<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<mships-form-header :modelPk="modelPk"></mships-form-header>
		</pui-form-header>
		<mships-modals :modelName="modelName"></mships-modals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('mships.tabs.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#mshipversion'" v-if="!isCreatingElement && !isModalDialog">{{ $t('mships.tabs.shipsversion') }}</v-tab>
				<v-tab :key="2" :href="'#portcalls'" v-if="!isCreatingElement && !isModalDialog">{{ $t('mships.tabs.portcalls') }}</v-tab>
				<v-tab :key="3" :href="'#tab_documents'" v-if="!isCreatingElement && !isModalDialog">{{ $t('documentextrafields.tab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" :value="'maintab'">
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-field-set :title="getTitleGeneralData">
								<v-layout wrap>
									<v-flex xs12 md6 lg6 x6 v-if="!this.isCreatingElement" align-self-center>
										<!-- INACTIVEIND -->
										<pui-checkbox
											:id="`inactiveind-mships`"
											v-model="model.inactiveind"
											:label="$t('mships.inactiveind')"
											:disabled="isShipStatusDisabled"
											required
											style="margin-bottom: 20px; margin-left: -2%"
											true-value="1"
											false-value="0"
										></pui-checkbox>
									</v-flex>
									<v-flex xs12 md6 lg6 x6 v-if="!this.isCreatingElement">
										<!-- SHIPSTATUSID -->
										<pui-select
											:id="`shipstatusid-mships`"
											:attach="`shipstatusid-mships`"
											:label="$t('mships.shipstatusid')"
											:disabled="!this.isCreatingElement"
											required
											toplabel
											clearable
											modelName="mshipstatus"
											v-model="model"
											reactive
											:itemsToSelect="shipstatusidItemsToSelect"
											:modelFormMapping="{ shipstatusid: 'shipstatusid' }"
											:itemValue="['shipstatusid']"
											:itemText="(item) => `${item.desc}`"
										></pui-select>
									</v-flex>
									<v-flex xs12 md6 lg6 x6 v-if="!this.isCreatingElement">
										<!-- shipid -->
										<pui-number-field
											:id="`shipid-mships`"
											v-model="model.shipid"
											:label="$t('mships.shipid')"
											:disabled="!this.isCreatingElement"
											required
											toplabel
											integer
											min="0"
											max="99999"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md6 lg6 x6 v-if="!this.isCreatingElement">
										<!-- VERSION -->
										<pui-number-field
											:id="`version-mships`"
											v-model="model.version"
											:label="$t('mships.version')"
											:disabled="!this.isCreatingElement"
											required
											toplabel
											integer
											min="0"
											max="99999"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md6 lg6 x6>
										<!-- INIVALIDITY -->
										<pui-date-field
											:id="`inivalidity-mships`"
											v-model="model.inivalidity"
											:label="$t('mships.inivalidity')"
											:disabled="!this.isCreatingElement"
											required
											toplabel
										></pui-date-field>
									</v-flex>
									<v-flex xs12 md6 lg6 x6 v-if="!this.isCreatingElement">
										<!-- ENDVALIDITY -->
										<pui-date-field
											:id="`endvalidity-mships`"
											v-model="model.endvalidity"
											:label="$t('mships.endvalidity')"
											:min="model.inivalidity"
											disabled
											toplabel
										></pui-date-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12 md12 lg12 x12>
										<!-- SHIPNAME -->
										<pui-text-field
											:id="`shipname-mships`"
											v-model="model.shipname"
											:label="$t('mships.shipname')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											required
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-flex>
									<v-flex xs12 md12 lg12 x12>
										<!-- SHIPTYPECODE -->
										<pui-select
											:id="`shiptypecode-mships`"
											:attach="`shiptypecode-mships`"
											:label="$t('mships.shiptypecode')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											required
											toplabel
											clearable
											modelName="mshiptypes"
											v-model="model"
											reactive
											:itemsToSelect="shiptypecodeItemsToSelect"
											:modelFormMapping="{ shiptypecode: 'shiptypecode' }"
											:itemValue="['shiptypecode']"
											:itemText="(item) => `${item.shiptypecode} - ${item.shiptypedesc}`"
											@change="changeShipTypeCode($event)"
										></pui-select>
									</v-flex>
									<v-flex xs12 md12 lg6 x6>
										<!-- IMO -->
										<pui-text-field
											:id="`imo-mships`"
											v-model="model.imo"
											:label="$t('mships.imo')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											required
											toplabel
											maxlength="7"
										></pui-text-field>
									</v-flex>
									<v-flex xs12 md12 lg6 x6>
										<!-- CALLSIGN -->
										<pui-text-field
											:id="`callsign-mships`"
											v-model="model.callsign"
											:label="$t('mships.callsign')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
											required
											maxlength="7"
										></pui-text-field>
									</v-flex>
									<v-flex xs12 md12 lg12 x12>
										<!-- FLAG -->
										<pui-select
											:id="`flag-mships`"
											:attach="`flag-mships`"
											:label="$t('mships.flag')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											required
											toplabel
											clearable
											modelName="mcountries"
											v-model="model"
											reactive
											:itemsToSelect="flagItemsToSelect"
											:modelFormMapping="{ countrycode: 'flag' }"
											:itemValue="['countrycode']"
											:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12 md12 lg6 x6>
										<!-- MMSI -->
										<pui-text-field
											:id="`mmsi-mships`"
											v-model="model.mmsi"
											:label="$t('mships.mmsi')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
											maxlength="9"
										></pui-text-field>
									</v-flex>
									<v-flex xs12 md12 lg6 x2>
										<!-- INMARSATCALLNR -->
										<pui-text-field
											:id="`inmarsatcallnr-mships`"
											v-model="model.inmarsatcallnr"
											:label="$t('mships.inmarsatcallnr')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
											maxlength="50"
										></pui-text-field>
									</v-flex>
								</v-layout>
							</pui-field-set>
						</v-flex>
						<v-flex xs12 md8>
							<pui-field-set :title="getTitleTechnicalData">
								<v-layout wrap>
									<v-flex xs12 md3 lg3 x3>
										<!-- LENGTH -->
										<pui-number-field
											:id="`length-mships`"
											v-model="model.length"
											:label="$t('mships.length')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											required
											toplabel
											max="999.99"
											suffix="m"
											decimals="2"
											min="1"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md3 lg3 xl3>
										<!-- BEAM -->
										<pui-number-field
											:id="`beam-mships`"
											v-model="model.beam"
											:label="$t('mships.beam')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											required
											toplabel
											suffix="m"
											max="99.99"
											decimals="2"
											min="1"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md3 lg3 xl3>
										<!-- MAXDRAFT -->
										<pui-number-field
											:id="`maxdraft-mships`"
											v-model="model.maxdraft"
											:label="$t('mships.maxdraft')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											required
											suffix="m"
											toplabel
											max="999.99"
											min="1"
											decimals="2"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md3 lg3 xl3>
										<!-- DM-->
										<pui-number-field
											:id="`dm-mships`"
											v-model="model.dm"
											:label="$t('mships.dm')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											suffix="m"
											required
											toplabel
											max="999.99"
											min="1"
											decimals="2"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md3 lg3 x3>
										<!-- GROSSTONNAGE -->
										<pui-number-field
											:id="`grosstonnage-mships`"
											v-model="model.grosstonnage"
											:label="$t('mships.grosstonnage')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											required
											toplabel
											max="99999999999.999"
											decimals="3"
											suffix="t"
											min="1"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md3 lg3 x3>
										<!-- REDGROSSTONNAGE -->
										<pui-number-field
											:id="`redgrosstonnage-mships`"
											v-model="model.redgrosstonnage"
											:label="$t('mships.redgrosstonnage')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
											max="99999999999.999"
											decimals="3"
											suffix="t"
											min="1"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md3 lg3 x3>
										<!-- NETTONNAGE -->
										<pui-number-field
											required
											:id="`nettonnage-mships`"
											v-model="model.nettonnage"
											:label="$t('mships.nettonnage')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
											max="99999999999.999"
											decimals="3"
											suffix="t"
											min="1"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md3 lg3 xl3>
										<!-- DWT-->
										<pui-number-field
											:id="`dwt-mships`"
											v-model="model.dwt"
											:label="$t('mships.dwt')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											required
											toplabel
											max="99999999999.999"
											decimals="3"
											suffix="t"
											:rules="[(v) => !v || !equalsZero(v) || $t('validation.notValidNumber', [v])]"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md3 lg3 x3>
										<!-- HULLTYPEID -->
										<pui-select
											:id="`hulltypeid-mships`"
											:attach="`hulltypeid-mships`"
											:label="$t('mships.hulltypeid')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
											clearable
											modelName="mhulltypes"
											v-model="model"
											reactive
											:required="
												this.model.shiptypecode == 53 ||
												this.model.shiptypecode == 531 ||
												this.model.shiptypecode == 532 ||
												this.model.shiptypecode == 533 ||
												this.model.shiptypecode == 534 ||
												this.model.shiptypecode == 54 ||
												this.model.shiptypecode == 541 ||
												this.model.shiptypecode == 542 ||
												this.model.shiptypecode == 543 ||
												this.model.shiptypecode == 55 ||
												this.model.shiptypecode == 551 ||
												this.model.shiptypecode == 552 ||
												this.model.shiptypecode == 553
											"
											:itemsToSelect="hulltypeidItemsToSelect"
											:modelFormMapping="{ hulltypeid: 'hulltypeid' }"
											:itemValue="['hulltypeid']"
											:itemText="(item) => `${item.desc}`"
										></pui-select>
									</v-flex>
									<v-flex xs12 md3 lg3 x3>
										<!-- MOORINGLINESNUM -->
										<pui-number-field
											:id="`mooringlinesnum-mships`"
											v-model="model.mooringlinesnum"
											:label="$t('mships.mooringlinesnum')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											:required="
												this.model.shiptypecode == 53 ||
												this.model.shiptypecode == 531 ||
												this.model.shiptypecode == 532 ||
												this.model.shiptypecode == 533 ||
												this.model.shiptypecode == 534 ||
												this.model.shiptypecode == 54 ||
												this.model.shiptypecode == 541 ||
												this.model.shiptypecode == 542 ||
												this.model.shiptypecode == 543 ||
												this.model.shiptypecode == 55 ||
												this.model.shiptypecode == 551 ||
												this.model.shiptypecode == 552 ||
												this.model.shiptypecode == 553
											"
											toplabel
											max="99999"
											integer
											min="0"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md3 lg3 x3>
										<!-- MOORINGLINETYPEID -->
										<pui-select
											:id="`mooringlinetypeid-mships`"
											:attach="`mooringlinetypeid-mships`"
											:label="$t('mships.mooringlinetypeid')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											:required="
												this.model.shiptypecode == 53 ||
												this.model.shiptypecode == 531 ||
												this.model.shiptypecode == 532 ||
												this.model.shiptypecode == 533 ||
												this.model.shiptypecode == 534 ||
												this.model.shiptypecode == 54 ||
												this.model.shiptypecode == 541 ||
												this.model.shiptypecode == 542 ||
												this.model.shiptypecode == 543 ||
												this.model.shiptypecode == 55 ||
												this.model.shiptypecode == 551 ||
												this.model.shiptypecode == 552 ||
												this.model.shiptypecode == 553
											"
											toplabel
											clearable
											modelName="mmooringlinetypes"
											v-model="model"
											reactive
											:itemsToSelect="mooringlinetypeidItemsToSelect"
											:modelFormMapping="{ mooringlinetypeid: 'mooringlinetypeid' }"
											:itemValue="['mooringlinetypeid']"
											:itemText="(item) => `${item.desc}`"
										></pui-select>
									</v-flex>
									<v-flex xs12 md3 lg3 x3>
										<!-- MOORINGWINCHESNUM -->
										<pui-number-field
											:id="`mooringwinchesnum-mships`"
											v-model="model.mooringwinchesnum"
											:label="$t('mships.mooringwinchesnum')"
											required
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
											max="99999"
											min="0"
											integer
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md3 lg3 xl3>
										<!-- POWER -->
										<pui-number-field
											:id="`power-mships`"
											v-model="model.power"
											:label="$t('mships.power')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											suffix="kW"
											toplabel
											max="99999"
											min="0"
											integer
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md3 lg3 x3>
										<!-- USEDFUEL -->
										<pui-text-field
											:id="`usedfuel-mships`"
											v-model="model.usedfuel"
											:label="$t('mships.usedfuel')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
										></pui-text-field>
									</v-flex>
									<v-flex xs12 md3 lg3 x3>
										<!-- NUMTHRUSTER -->
										<pui-number-field
											:id="`numthruster-mships`"
											v-model="model.numthruster"
											:label="$t('mships.numthruster')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
											max="99999"
											min="0"
											integer
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md3 lg3 x3>
										<!-- THRUSTERPOSITION -->
										<pui-select
											:id="`thrusterposition-mships`"
											:attach="`thrusterposition-mships`"
											:label="$t('mships.thrusterposition')"
											itemText="text"
											itemValue="value"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
											clearable
											v-model="model"
											reactive
											:items="positions"
											:itemsToSelect="thrusterpositionItemsToSelect"
											:modelFormMapping="{ value: 'thrusterposition' }"
										></pui-select>
									</v-flex>
									<v-flex xs12 md3 lg3 x3>
										<!-- SWL -->
										<pui-number-field
											:id="`swl-mships`"
											v-model="model.swl"
											:label="$t('mships.swl')"
											required
											toplabel
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											integer
											min="0"
											max="99999"
										></pui-number-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs3 md3 lg3 x3>
										<!-- SBTIND -->
										<pui-checkbox
											:id="`sbtind-mships`"
											v-model="model.sbtind"
											:label="$t('mships.sbtind')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA') ||
												this.model.shiptypecode == 53 ||
												this.model.shiptypecode == 531 ||
												this.model.shiptypecode == 532 ||
												this.model.shiptypecode == 533 ||
												this.model.shiptypecode == 534 ||
												this.model.shiptypecode == 54 ||
												this.model.shiptypecode == 541 ||
												this.model.shiptypecode == 542 ||
												this.model.shiptypecode == 543 ||
												this.model.shiptypecode == 55 ||
												this.model.shiptypecode == 551 ||
												this.model.shiptypecode == 552 ||
												this.model.shiptypecode == 553
											"
											true-value="1"
											false-value="2"
											style="margin-bottom: 20px"
										></pui-checkbox>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12 md4 lg4 x4 v-if="model.sbtind == 1">
										<!-- SBTNUMB -->
										<pui-number-field
											:id="`sbtnumb-mships`"
											v-model="model.sbtnumb"
											:label="$t('mships.sbtnumb')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											:required="
												(this.model.sbtind == 1 &&
													(this.model.shiptypecode == 53 ||
														this.model.shiptypecode == 531 ||
														this.model.shiptypecode == 532 ||
														this.model.shiptypecode == 533 ||
														this.model.shiptypecode == 534 ||
														this.model.shiptypecode == 54 ||
														this.model.shiptypecode == 541 ||
														this.model.shiptypecode == 542 ||
														this.model.shiptypecode == 543 ||
														this.model.shiptypecode == 55 ||
														this.model.shiptypecode == 551 ||
														this.model.shiptypecode == 552 ||
														this.model.shiptypecode == 553)) ||
												this.model.sbtind == 1
											"
											toplabel
											max="99999"
											min="0"
											integer
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md4 lg4 x4 v-if="model.sbtind == 1">
										<!-- SBTCAPACITY -->
										<pui-number-field
											:id="`sbtcapacity-mships`"
											v-model="model.sbtcapacity"
											:label="$t('mships.sbtcapacity')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											:required="
												(this.model.sbtind == 1 &&
													(this.model.shiptypecode == 53 ||
														this.model.shiptypecode == 531 ||
														this.model.shiptypecode == 532 ||
														this.model.shiptypecode == 533 ||
														this.model.shiptypecode == 534 ||
														this.model.shiptypecode == 54 ||
														this.model.shiptypecode == 541 ||
														this.model.shiptypecode == 542 ||
														this.model.shiptypecode == 543 ||
														this.model.shiptypecode == 55 ||
														this.model.shiptypecode == 551 ||
														this.model.shiptypecode == 552 ||
														this.model.shiptypecode == 553)) ||
												this.model.sbtind == 1
											"
											toplabel
											max="9999999999.999998"
											decimals="6"
											:rules="[(v) => !v || !equalsZero(v) || $t('validation.notValidNumber', [v])]"
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md4 lg4 x4 v-if="model.sbtind == 1">
										<!-- MEASUREMENTUNITCODE -->
										<pui-select
											:id="`measurementunitcode-mships`"
											:attach="`measurementunitcode-mships`"
											:label="$t('mships.measurementunitcode')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
											clearable
											modelName="mmeasurementunits"
											v-model="model"
											:required="
												(this.model.sbtind == 1 &&
													(this.model.shiptypecode == 53 ||
														this.model.shiptypecode == 531 ||
														this.model.shiptypecode == 532 ||
														this.model.shiptypecode == 533 ||
														this.model.shiptypecode == 534 ||
														this.model.shiptypecode == 54 ||
														this.model.shiptypecode == 541 ||
														this.model.shiptypecode == 542 ||
														this.model.shiptypecode == 543 ||
														this.model.shiptypecode == 55 ||
														this.model.shiptypecode == 551 ||
														this.model.shiptypecode == 552 ||
														this.model.shiptypecode == 553)) ||
												this.model.sbtind == 1
											"
											reactive
											:itemsToSelect="measurementUnitCodeItemsToSelect"
											:modelFormMapping="{ measurementunitcode: 'measurementunitcode' }"
											:itemValue="['measurementunitcode']"
											:itemText="(item) => `${item.measurementunitsymbol} - ${item.typedescription}`"
											:fixedFilter="filterMeasurementUnitCode"
										></pui-select>
									</v-flex>
								</v-layout>

								<v-layout wrap>
									<v-flex xs12 md12 lg12 x12 v-if="!this.isCreatingElement && this.model.shipstatusid == 2">
										<!-- REJECTIONREASON -->
										<pui-text-area
											:id="`rejectionreason-mships`"
											v-model="model.rejectionreason"
											:label="$t('mships.rejectionreason')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA') ||
												this.model.shipstatusid == 2
											"
											toplabel
										></pui-text-area>
									</v-flex>
								</v-layout>
							</pui-field-set>
						</v-flex>
						<v-flex xs12 md12 lg12 x12>
							<pui-field-set :title="getTitleAdditionalData">
								<v-layout wrap>
									<v-flex xs12 md4 lg4 x4>
										<pui-text-field
											:id="`mships-owneroperator`"
											required
											v-model="model.owneroperator"
											:label="$t('mships.owneroperator')"
											toplabel
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
										></pui-text-field>
									</v-flex>

									<!-- registryportcountry -->
									<v-flex xs12 md4 lg4 x4>
										<pui-select
											id="`mships-registryportcountry`"
											attach="mships-registryportcountry"
											:label="$t('mships.registryportcountry')"
											toplabel
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											reactive
											required
											clearable
											v-model="model"
											modelName="mports"
											:modelFormMapping="{ countrycode: 'certregistrycountry', portcode: 'certregistryport' }"
											:itemsToSelect="[{ countrycode: model.certregistrycountry, portcode: model.certregistryport }]"
											:itemValue="['countrycode', 'portcode']"
											:itemText="(item) => `${item.locode} - ${item.portdescription}`"
											:order="{ locode: 'asc' }"
										></pui-select>
									</v-flex>
									<v-flex xs12 md12 lg4 x4>
										<!-- CONSTRUCTIONDATE -->
										<pui-date-field
											:id="`constructiondate-mships`"
											v-model="model.constructiondate"
											:label="$t('mships.constructiondate')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
											:max="getMaxDate()"
										></pui-date-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12 md4 lg4 x4>
										<!-- CLASSSOCIETY -->
										<pui-text-field
											:id="`classsociety-mships`"
											v-model="model.classsociety"
											:label="$t('mships.classsociety')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
											maxlength="100"
										></pui-text-field>
									</v-flex>
									<v-flex xs12 md8 lg8 x8>
										<!-- CLASSNOTATION -->
										<pui-text-field
											:id="`classnotation-mships`"
											v-model="model.classnotation"
											:label="$t('mships.classnotation')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
										></pui-text-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12 md12 lg12 x12>
										<!-- OBSERVATIONS -->
										<pui-text-area
											:id="`observations-mships`"
											v-model="model.observations"
											:label="$t('mships.observations')"
											:disabled="
												formDisabled ||
												(this.model.shipstatusid == 3 && !this.isCreatingElement) ||
												this.$store.getters.getSession.profiles.includes('HAMA') ||
												this.$store.getters.getSession.profiles.includes('MTA')
											"
											toplabel
										></pui-text-area>
									</v-flex>
								</v-layout>
							</pui-field-set>
						</v-flex>
					</v-layout>
				</v-tab-item>

				<v-tab-item :key="1" :value="'mshipversion'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="mshipsversion-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ shipid: 'shipid' }"
						:formDisabled="formDisabled"
						:modalDialog="isModalDialog"
						:showCreateBtn="false"
						:showDeleteBtn="false"
						:readOnly="true"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="2" :value="'portcalls'" lazy v-if="!isCreatingElement">
					<pui-master-detail
						componentName="portcalls-grid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ shipid: 'shipid' }"
						:formDisabled="formDisabled"
						:modalDialog="isModalDialog"
						:showCreateBtn="false"
						:showDeleteBtn="false"
						:readOnly="true"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="3" :value="'tab_documents'" v-if="!isCreatingElement">
					<pui-master-detail componentName="documentextrafields-grid" :parentModelName="modelName" :parentPk="pk"></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="
						(this.model.shipstatusid == 3 && !isCreatingElement && !this.$store.getters.getSession.profiles.includes('HAMA')) ||
						this.$store.getters.getSession.profiles.includes('MTA')
					"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mshipsActions from './MshipsActions';
import MshipsModals from './MshipsModals.vue';
import { STATUS_DRAFT_DESC } from '../../constants/imofalforms/ImoFalFormsConstants';
import { DURACION } from '../../constants/notification/NotificationConstant';
export default {
	name: 'mships-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mships-modals': MshipsModals
	},
	data() {
		return {
			modelName: 'mships',
			tabmodel: 'maintab',
			inivalidity: null,
			rol: this.$store.getters.getSession.profiles,
			actions: mshipsActions.formactions,
			positions: [
				{
					text: this.$t('mships.Bow'),
					value: 'Bow'
				},
				{
					text: this.$t('mships.Stern'),
					value: 'Stern'
				}
			],
			statusDraftDesc: STATUS_DRAFT_DESC
		};
	},
	methods: {
		afterGetData() {},
		changeShipTypeCode(e) {
			if (
				e != null &&
				e != undefined &&
				(e.shiptypecode == 53 ||
					e.shiptypecode == 531 ||
					e.shiptypecode == 532 ||
					e.shiptypecode == 533 ||
					e.shiptypecode == 534 ||
					e.shiptypecode == 54 ||
					e.shiptypecode == 541 ||
					e.shiptypecode == 542 ||
					e.shiptypecode == 543 ||
					e.shiptypecode == 55 ||
					e.shiptypecode == 551 ||
					e.shiptypecode == 552 ||
					e.shiptypecode == 553)
			) {
				this.model.sbtind = '1';
			} else {
				if (this.model.sbtind != '1') {
					this.model.sbtind = '0';
				}
			}
		},
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;

			return permissions.every((permission) => allowedForms.includes(permission));
		},
		getMaxDate() {
			const currentDate = new Date();
			const year = currentDate.getFullYear();
			const month = currentDate.getMonth() + 1;
			const day = currentDate.getDate();
			return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
		},
		custom() {
			return new Promise((resolve) => {
				this.$puiRequests.postRequest(
					this.$store.getters.getModelByName(this.modelName).url.existImo,
					{},
					(response) => {
						if (response.data === true) {
							this.$puiNotify.error('Error, the IMO entered already exists', '', DURACION, true);
							resolve(true);
						} else {
							this.save();
						}
					},
					(e) => {
						this.$puiNotify.error(e.response.data.message, 'Error', DURACION, true);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						resolve(true);
					},
					null,
					{ imo: this.model.imo }
				);
			});
		},
		beforeSave() {},
		equalsZero(value) {
			return value != null && value == 0;
		}
	},
	computed: {
		shiptypecodeItemsToSelect() {
			return [{ shiptypecode: this.model.shiptypecode }];
		},
		flagItemsToSelect() {
			return [{ countrycode: this.model.flag }];
		},
		shipstatusidItemsToSelect() {
			return [{ shipstatusid: this.model.shipstatusid }];
		},
		thrusterpositionItemsToSelect() {
			return [{ value: this.model.thrusterposition }];
		},
		measurementUnitCodeItemsToSelect() {
			return [{ measurementunitcode: this.model.measurementunitcode }];
		},
		hulltypeidItemsToSelect() {
			return [{ hulltypeid: this.model.hulltypeid }];
		},
		mooringlinetypeidItemsToSelect() {
			return [{ mooringlinetypeid: this.model.mooringlinetypeid }];
		},
		getTitleTechnicalData() {
			return this.$t('mships.technicalData');
		},
		getTitleAdditionalData() {
			return this.$t('mships.additionalData');
		},
		getTitleGeneralData() {
			return this.$t('mships.generalData');
		},
		filterMeasurementUnitCode() {
			return {
				groups: [
					{
						groupOp: 'or',
						rules: [
							{ field: 'measurementunitcode', op: 'eq', data: 'LTR' },
							{ field: 'measurementunitcode', op: 'eq', data: 'MTQ' }
						]
					}
				]
			};
		},
		isShipStatusDisabled() {
			const currentUserRole = this.$store.getters.getSession.profiles;
			const shipStatusId = this.model.shipstatusid;
			return !(
				(currentUserRole.includes('HAMA') || currentUserRole.includes('ALL')) &&
				(shipStatusId === 1 || shipStatusId === 3 || shipStatusId === 2)
			);
		}
	},
	watch: {
		'model.sbtind'() {
			if (this.model.sbtind === '2') {
				this.model.sbtnumb = null;
				this.model.sbtcapacity = null;
				this.model.measurementunitcode = null;
			}
		}
	}
};
</script>
